import {
	HiOutlineViewGrid,
	HiOutlineCube,
	HiOutlineShoppingCart,
	HiOutlineUsers,
	HiOutlineDocumentText,
	HiOutlineAnnotation,
	HiOutlineQuestionMarkCircle,
	HiOutlineCog
} from 'react-icons/hi'

import {
	MdOutlineManageAccounts
} from 'react-icons/md'

export const DASHBOARD_SIDEBAR_LINKS = [
	{
		key: 'dashboard',
		label: 'Dashboard',
		path: '/',
		icon: <HiOutlineViewGrid />,
		subkeys: []
	},
	{
		key: 'products',
		label: 'Products',
		path: '/products',
		icon: <HiOutlineCube />,
		subkeys: []
	},
	{
		key: 'ppp',
		label: 'Hotspot',
		path: '/ppp',
		icon: <MdOutlineManageAccounts />,
		subkeys: [
		{
			key: 'packages',
			label: 'Packages',
			path: '/packages',
		},
		{
			key: 'access-points',
			label: 'Access Points',
			path: '/access-points',
		},
		{
			key: 'devices',
			label: 'Clients',
			path: '/devices',
		},
		{
			key: 'events',
			label: 'Events',
			path: '/events',
		},
		{
			key: 'staticPage',
			label: 'Static Page',
			path: '/guest/s/default',
		}
	]
	},
	{
		key: 'transactions',
		label: 'Transactions',
		path: '/transactions',
		icon: <HiOutlineDocumentText />,
		subkeys: [
			{
				key: 'payments',
				label: 'Hotspot',
				path: '/payments',
			},
		]
	},
	{
		key: 'messages',
		label: 'Reports',
		path: '/messages',
		icon: <HiOutlineAnnotation />,
		subkeys: [
			{
				key: 'connections',
				label: 'Hotspot',
				path: '/connections',
			},
		]
	},
	{
		key: 'settings',
		label: 'Settings',
		path: '/settings',
		icon: <HiOutlineCog />,
		subkeys: [{
			key: 'hotspot',
			label: 'Hotspot',
			path: '/unifi',
		},
		{
			key: 'mpesa-express',
			label: 'M-Pesa Express',
			path: '/mpesa-express',
		}
	]
	},
]

export const DASHBOARD_SIDEBAR_BOTTOM_LINKS = [
	{
		key: 'support',
		label: 'Help & Support',
		path: '/support',
		icon: <HiOutlineQuestionMarkCircle />
	}
]
