import React, { useState } from 'react';
import classNames from 'classnames';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { FcBullish } from 'react-icons/fc';
import { HiOutlineLogout } from 'react-icons/hi';
import { DASHBOARD_SIDEBAR_LINKS, DASHBOARD_SIDEBAR_BOTTOM_LINKS } from '../../lib/constants';

const linkClass =
  'flex items-center gap-2 font-light px-3 py-2 hover:bg-neutral-700 hover:no-underline active:bg-neutral-600 rounded-sm text-base';

export default function Sidebar() {
  const [openDropdown, setOpenDropdown] = useState(null);
  const navigate = useNavigate()

  const toggleDropdown = (key) => {
    if (openDropdown === key) {
      setOpenDropdown(null);
    } else {
      setOpenDropdown(key);
    }
  };

  return (
    <div className="bg-neutral-900 w-60 p-3 flex flex-col">
      <div className="flex items-center gap-2 px-1 py-3">
        <FcBullish fontSize={24} />
        <span className="text-neutral-200 text-lg">UniFi</span>
      </div>
      <div className="py-8 flex flex-1 flex-col gap-0.5">
        {DASHBOARD_SIDEBAR_LINKS.map((link) => (
          link.subkeys.length === 0 ?
            <SidebarLink key={link.key} link={link} /> :
            <SidebarDropdownLink
              key={link.key}
              link={link}
              isOpen={openDropdown === link.key}
              toggleDropdown={() => toggleDropdown(link.key)}
            />
        ))}
      </div>
      <div className="flex flex-col gap-0.5 pt-2 border-t border-neutral-700">
        {DASHBOARD_SIDEBAR_BOTTOM_LINKS.map((link) => (
          <SidebarLink key={link.key} link={link} />
        ))}
        <div className={classNames(linkClass, 'cursor-pointer text-red-500')} onClick={() => navigate('/logout')}>
          <span className="text-xl">
            <HiOutlineLogout />
          </span>
          Logout
        </div>
      </div>
    </div>
  );
}

function SidebarLink({ link }) {
  const { pathname } = useLocation();

  return (
    <Link
      to={link.path}
      className={classNames(pathname === link.path ? 'bg-neutral-700 text-white' : 'text-neutral-400', linkClass)}
    >
      <span className="text-xl">{link.icon}</span>
      {link.label}
    </Link>
  );
}

function SidebarDropdownLink({ link, isOpen, toggleDropdown }) {
  const { pathname } = useLocation();

  return (
    <div>
      <div
        onClick={toggleDropdown}
        className={classNames(pathname === link.path ? 'bg-neutral-700 text-white' : 'text-neutral-400', linkClass)}
      >
        <span className="text-xl">{link.icon}</span>
        {link.label}
      </div>
      {isOpen && (
        <div className="pl-7">
          {link.subkeys.map((subLink) => (
            <Link
              key={subLink.key}
              to={subLink.path}
              className={classNames(pathname === subLink.path ? 'bg-neutral-700 text-white' : 'text-neutral-400', linkClass)}
            >
              {subLink.label}
            </Link>
          ))}
        </div>
      )}
    </div>
  );
}
