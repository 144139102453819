import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom'
import Layout from './components/shared/Layout'
import Register from './pages/Register'
import Login from './pages/Login'
import Dashboard from './pages/Dashboard'
import Products from './pages/Products'
import ProtectedRoute from './components/ProtectedRoutes'
import StaticPage from './pages/StaticPage'
import Phone from './pages/Phone'
import HotspotPackage from './pages/HotspotPackage'
import Unifi from './pages/Unifi'
import ActiveDevices from './pages/ActiveDevices'
import AccessPoints from './pages/AccessPoints'
import HotspotEvents from './pages/HotspotEvents'
import PaymentsReport from './pages/PaymentsReport'
import MpesaExpress from './pages/MpesaExpress'
import ConnectionsReport from './pages/ConnectionsReport '


function Logout() {
    localStorage.clear()
    return <Navigate to="/login" />
}

function RegisterAndLogout() {
    localStorage.clear()
    return <Register />
}

function App() {
    return (
        <Router>
            <Routes>
                <Route path="/login" element={<Login />} />
                <Route path="/" element={<ProtectedRoute> <Layout /> </ProtectedRoute>}>
                    <Route index element={<ProtectedRoute> <Dashboard /> </ProtectedRoute>} />
                    <Route path="products" element={<ProtectedRoute> <Products /> </ProtectedRoute>} />
                    <Route path="/packages" element={<ProtectedRoute> <HotspotPackage /> </ProtectedRoute>} />
                    <Route path="/unifi" element={<ProtectedRoute> <Unifi /> </ProtectedRoute>} />
                    <Route path="/devices" element={<ProtectedRoute> <ActiveDevices /> </ProtectedRoute>} />
                    <Route path="/access-points" element={<ProtectedRoute> <AccessPoints /> </ProtectedRoute>} />
                    <Route path="/events" element={<ProtectedRoute> <HotspotEvents /> </ProtectedRoute>} />
                    <Route path="/payments" element={<ProtectedRoute> <PaymentsReport /> </ProtectedRoute>} />
                    <Route path="/connections" element={<ProtectedRoute> <ConnectionsReport/> </ProtectedRoute>} />
                    <Route path="/mpesa-express" element={<ProtectedRoute> <MpesaExpress/> </ProtectedRoute>} />
                </Route>
                <Route path="/register" element={<RegisterAndLogout />} />
                <Route path="/logout" element={<Logout />} />
                <Route path="/guest/s/default" element={<StaticPage />} />
                <Route path="/phone" element={<Phone />} />
            </Routes>
        </Router>
    )
}

export default App
